import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import {
//   MatCardModule,
//   MatButtonModule,
//   MatIconModule,
//   MatMenuModule
// } from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WeatherComponent } from './weather/weather.component';
import { IncidentComponent } from './incident/incident.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RollcallComponent } from './rollcall/rollcall.component';
import { CommunityComponent } from './community/community.component';
import { ResponseComponent } from './response/response.component';
import { TourComponent } from './tour/tour.component';
import { ActivationComponent } from './activation/activation.component';
import { MenuComponent } from './menu/menu.component';
import { DataService } from './data.service';
import { DutyComponent } from './duty/duty.component';
import { IncidentSummaryComponent } from './incident-summary/incident-summary.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { StaffComponent } from './staff/staff.component';
import { WeatherCardComponent } from './weather-card/weather-card.component';
import { CovidComponent } from './covid/covid.component';
import { OutageComponent } from './outage/outage.component';
import { ChartsModule } from 'ng2-charts';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { OAuthConfig, protectedResourceMap } from 'src/config/config';

//Check if this browser is IE (Internet Explorer) for special configurations
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


@NgModule({
	declarations: [
		AppComponent,
		WeatherComponent,
		IncidentComponent,
		RollcallComponent,
		CommunityComponent,
		ResponseComponent,
		TourComponent,
		ActivationComponent,
		MenuComponent,
		DutyComponent,
		IncidentSummaryComponent,
		VehicleComponent,
		StaffComponent,
		WeatherCardComponent,
		CovidComponent,
		OutageComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		MaterialModule,
		BrowserAnimationsModule,
		HttpClientModule,
		ChartsModule,
		MsalModule.forRoot({
			auth: OAuthConfig,
			cache: {
				cacheLocation: 'localStorage',
				storeAuthStateInCookie: isIE, // set to true for IE 11
			},
		},
			{
				popUp: isIE,  // set to false for IE 11
				protectedResourceMap: protectedResourceMap
			}
		)

		// MatCardModule,
		// MatButtonModule,
		// MatIconModule,
		// MatMenuModule
	],
	providers: [DataService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
