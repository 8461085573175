import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Outage, Borough } from './interface';
import { Observable, Subscription, timer } from 'rxjs';
// import { analyzeAndValidateNgModules } from '@angular/compiler';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { typeofExpr } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-outage',
  templateUrl: './outage.component.html',
  styleUrls: ['./outage.component.css'],
})
export class OutageComponent implements OnInit, OnDestroy {
  private OUTAGE_URL = environment.serverUrl + '/api-outage';
  private FIVE_MINUTES = 10 * 1000;
  private timer;
  private sub: Subscription;
  outageCards: Borough[] = [];
  response: any;
  private alertThreshold: number = 0;
  private alertCustomerOutage: number[] = [];
  private alertLabel: string[] = [];
  public showChart: boolean;

  barChartOptions: ChartOptions = {
    responsive: true,
    tooltips: {
      // enabled: false,

      custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML =
            '<a href="https://www.w3schools.com">Visit W3Schools.com!</a> ';
          document.body.appendChild(tooltipEl);
        }
      },
    },
    // tooltips: [ 'Link: <a href="http://www.google.com" target="_blank" >Google</>' ],
    maintainAspectRatio: true,
    legend: {
      labels: {
        // This more specific font property overrides the global property
        fontColor: 'grey',
        fontSize: 24,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    animation: {
      duration: 0,
    },
  };

  options: {};
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  // barChartLabels: Label[] = this.alertLabel;
  // barChartData: ChartDataSets[] = [ { data: this.alertCustomerOutage, label: 'test' } ];

  barChartLabels: Label[] = [];
  barChartData: ChartDataSets[] = [{ data: [] }];
  // console.log('this.alertCustomerOutage :>> ', this.alertCustomerOutage);
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.timer = timer(0, this.FIVE_MINUTES);
    this.sub = this.timer.subscribe(() => {
      this.response = this.http.get<Outage>(this.OUTAGE_URL);
      this.response.subscribe((results) => {
        this.outageCards = results.outage.boroughs;
        // console.log('outageCards :>> ', this.outageCards.boroughs);
      });
      // console.log('this.outageCards :>> ', this.outageCards);
      if (this.outageCards) {
        this.alertCustomerOutage = [];
        this.alertLabel = [];
        let AlertLocation: Borough[] = [];
        this.outageCards.forEach((element) => {
          // element.push(element);
          if (element.customersOutage > this.alertThreshold) {
            this.showChart = true;

            AlertLocation.push(element);
            this.alertCustomerOutage.push(element.customersOutage);
            this.alertLabel.push(element.location);
          }
        });

        // console.log('customersOutage 0 :>> ', this.alertCustomerOutage[0]);
        // console.log('customersOutage 1 :>> ', this.alertCustomerOutage[1]);
        console.log('customersOutage :>> ', this.alertCustomerOutage);
        console.log('this.showChart :>> ', this.showChart);
        this.barChartLabels = this.alertLabel;
        this.barChartData = [
          {
            data: this.alertCustomerOutage,
            label: 'Customer Outages',
          },
        ];
      } else {
        let AlertLocation: Borough[] = [];
      }
    });

    console.log('TEST');
  }

  ngOnDestroy() {
    this.response.unsubscribe.next();
    this.response.unsubscribe.complete();
    this.sub.unsubscribe();
  }
}
