<app-menu (accountEvent)="user=$event"></app-menu>
<div *ngIf="this.user">
    <!-- <a _ngcontent-c1="" 
  class="docs-navbar-hide-small docs-button mat-button ng-star-inserted" m
  at-button="" tabindex="0" aria-disabled="false" href="/components">
  <span class="mat-button-wrapper">NYCEM</span>
  <div class="mat-button-ripple mat-ripple" matripple=""></div>
  <div class="mat-button-focus-overlay"></div>
</a> -->
    <div class="content">

        <div class="incident z-depth-1">
            <app-incident></app-incident>
        </div>

        <div class="non-incident">
            <app-outage></app-outage>
            <app-weather-card></app-weather-card>
            <!-- <app-weather></app-weather> -->
            <!-- <app-vehicle></app-vehicle> -->
            <app-staff></app-staff>
            <app-vehicle></app-vehicle>
            <!-- <app-response (duty)="duty=$event"></app-response> -->
            <app-rollcall></app-rollcall>
            <app-tour (tour)="tour=$event"></app-tour>
            <!-- <app-community></app-community> -->
        </div>

    </div>


    <!-- <div class="blank-filler"></div> -->




    <app-activation (activate)="activate=$event"></app-activation>
    <app-duty></app-duty>
    <router-outlet></router-outlet>

    </div>