<!-- 

<mat-toolbar class="mat-elevation-z8" color="primary" (act)="act=$event">
  <mat-toolbar-row>

    <button mat-flat-button color="primary" class='wc-button'>

      <mat-icon class="mat-button-wrapper material-icons md-10" wc-icon>menu</mat-icon>
    </button>

    <button mat-raised-button color="primary" class='wc-button'>
      <div>{{act?.activation}} </div>
    </button>

    <button mat-raised-button color="primary" class='wc-button'>
      <div>{{duty?.nextDuty}} </div>
    </button>
    <button mat-button class='wc-button'>NYCEM</button>
    <button mat-button class='wc-button'>NYCEM</button>

  </mat-toolbar-row>

</mat-toolbar> -->

<div *ngIf="this.user then logout else login"></div>

<ng-template #login>
    <div class="center">
        <br>
        <h2>Please sign-in with your NYCEM account</h2>
        <br>
        <button class="btn"  role="button"  (click)="signIn()">Sign In</button>
    </div>
</ng-template>

<ng-template #logout>
    <ul id="dropdown1" class="dropdown-content">
        <li><a href="#!">one</a></li>
        <li><a href="#!">two</a></li>
        <li class="divider"></li>
        <li><a href="#!">three</a></li>
    </ul>

    <mat-menu #menu="matMenu" class="my-full-width-menu">
        <ul class="collection">
            <li class="collection-item">
                <a href="http://cpv-app01/Ready_NY/" mat-menu-item target="_blank">Ready NY</a>
            </li>
            <li class="collection-item">
                <a href="http://cpv-app01.oem.nycnet/WatchCmd19/RA_Main.aspx" mat-menu-item target="_blank">Response Board</a>
            </li>
            <li class="collection-item">
                <a href="https://a858-nycnotify.nyc.gov/notifynyc/(S(xdfe5sdxwnudwkyivkcpengy))/home.aspx" mat-menu-item target="_blank">* Notify NYC</a>
            </li>
            <li class="collection-item">
                <a href="http://cpv-app01.oem.nycnet/WatchCmd19/VR_Main.aspx" mat-menu-item target="_blank">Con Ed Feeders</a>
            </li>
            <li class="collection-item">
                <a href="https://apps.coned.com/stormcenter/external/default.html" mat-menu-item target="_blank">* Con Ed Outages
      </a>
            </li>
            <li class="collection-item">
                <!-- !!! <p> fix me</p> -->
                <a href="http://cpv-app01/InterAgency18/Welcome.aspx" mat-menu-item target="_blank">Interagency Directory
      </a>
            </li>
            <li class="collection-item">
                <!-- !!! <p>FIX ME </p> -->
                <a href="https://nycem.oem.nycnet/wc/Shared%20Documents/Forms/AllItems.aspx?RootFolder=%2Fwc%2FShared%20Documents%2FOperating%20Guide&FolderCTID=0x012000C6F28F9F573F6646B0AEEB47F6B7F701&View=%7B21D5A5BA%2D2592%2D4497%2D8324%2DDF862F42D300%7D" mat-menu-item
                    target="_blank">* Operating Guide
      </a>
            </li>
        </ul>

        <!-- Location of Shift Reports - W:\Operations\Watch Command\Shift Reports -->
        <!-- Response Packages - W:\Operations\Watch Command\Initial Response Packages -->
    </mat-menu>
    <nav [ngClass]="showAct ? 'navActivate' : 'navNormal'">
        <div class="nav-wrapper nav-content">
            <mat-icon mat-button [matMenuTriggerFor]="menu" class="material-icons large dropdown-trigger mlocate">
                menu
            </mat-icon>
            <button class="large mat-button" mat-button [matMenuTriggerFor]="menu">
      <!-- <mat-icon matListIcon>menu</mat-icon> -->
    </button>

            <!-- <i class="material-icons large">menu</i> -->

            <ul class="right hide-on-med-and-down">
                <li>
                    <a href="#" target="_blank" class="unclickable">Welcome {{user.displayName.split(' ')[0]}}!</a>
                </li>
                <li *ngIf="showAct; else notShowAct">
                    <a href="https://cpv-int01.oem.nycnet/Watch-Command" target="_blank">
                        <div class="Activate" title="
{{ act.title }}
{{ act?.content }}
Begin: {{ act?.beginDate | date: 'EE, MMM d, HH:mm':'UTC' }}
UpDate: {{ act?.upDate | date: 'EE, MMM d, HH:mm':'UTC' }}">
                            ACTIVATED
                            <p class="datenow">{{ now | date: "EE, MMM d, HH:mm:ss" }}</p>
                        </div>
                    </a>
                </li>
                <ng-template #notShowAct>
                    <li>
                        <a href="https://cpv-int01.oem.nycnet/Watch-Command" target="_blank">
                            <div class="no-padding">
                                NYCEM
                                <p class="datenow">{{ now | date: "EE, MMM d, HH:mm:ss" }}</p>
                            </div>
                        </a>
                    </li>
                </ng-template>

                <li>
                    <a href="https://cpv-int01.oem.nycnet/Watch-Command" target="_blank">
          {{ duty?.currentName | uppercase }}
          <div class="no-padding">
            {{ duty?.currentStartDate | date: "MM/dd HH:mm" }} to
            {{ duty?.currentEndDate | date: "MM/dd HH:mm" }}
          </div>
        </a>
                </li>
                <li>
                    <a href="https://cpv-int01.oem.nycnet/Watch-Command" target="_blank">
          {{ duty?.nextName | uppercase }}
          <div class="no-padding">
            {{ duty?.nextStartDate | date: "MM/dd HH:mm" }} to
            {{ duty?.nextEndDate | date: "MM/dd HH:mm" }}
          </div>

        </a>
                </li>
                <li>
                    <a href="http://cpv-app01/ActiveTeams/CurRoster.aspx" target="_blank" [ngClass]="{
            'change-white': tour?.tourColor.startsWith('White'),
            'change-blue': tour?.tourColor.startsWith('Blue'),
            'change-red': tour?.tourColor.startsWith('Red')
          }">{{ tour?.tourColor | uppercase }},
          {{ tour?.leadLastName | uppercase }}
          <div class="no-padding">
            {{ tour?.tourStart | date: "MM/dd HH:mm" }} to
            {{ tour?.tourEnd | date: "MM/dd HH:mm" }}
          </div>
        </a>
                </li>

                <li>
                    <button mat-fab [matMenuTriggerFor]="signout" color="white">
                      <span class="material-icons larg">
                        person_outline
                      </span>
                    </button>
                    <!-- #enddocregion mat-menu-trigger-for -->
                    <mat-menu #signout="matMenu">
                        <ul class="center mat-body-strong user-icon">
                            <li>{{user.displayName}}</li>
                            <li><button mat-menu-item class="sign-btn" color="warn" (click)="signOut()">sign Out</button></li>
                        </ul>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </nav>
</ng-template>