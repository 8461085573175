import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RollCall } from './interface';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import { timer } from 'rxjs/observable/timer';
import { FindValueOperator } from 'rxjs/internal/operators/find';
import { Observable, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-rollcall',
  templateUrl: './rollcall.component.html',
  styleUrls: ['./rollcall.component.css'],
})
export class RollcallComponent implements OnInit, OnDestroy {
  // @ViewChild(MatSort) sort: MatSort;
  private serviceUrl = environment.serverUrl + '/api-RollCall';
  rollcalls: RollCall;
  FIVE_MINUTES = 5 * 60 * 1000;
  displayedColumns: string[] = ['RcName', 'NextExe'];

  private timer;
  private sub: Subscription;
  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.timer = timer(900, this.FIVE_MINUTES);

    this.sub = this.timer.subscribe(() => {
      this.http
        .get<RollCall>(this.serviceUrl)
        .subscribe((response) => {
          this.rollcalls = response;
          // console.log('this.rollcalls ====> ', this.rollcalls);
        });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
