import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Staff } from './interface';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import { timer } from 'rxjs/observable/timer';
import { FindValueOperator } from 'rxjs/internal/operators/find';
import { Observable, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css'],
})
export class StaffComponent implements OnInit, OnDestroy {
  private serviceUrl = environment.serverUrl + '/api-Staff';
  staffs: Staff;
  FIVE_MINUTES = 5 * 60 * 1000;
  displayedColumns: string[] = [
    'name',
    'start',
    'end',
    'radio',
    'status',
    'assignment',
  ];

  private timer: any;
  private sub: Subscription;
  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.timer = timer(850, this.FIVE_MINUTES);
    this.sub = this.timer.subscribe(() => {
      this.http.get<any>(this.serviceUrl).subscribe((response) => {
        this.staffs = response.staff.staff;
        // console.log(response);
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
