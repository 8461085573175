import { Component, OnInit, OnDestroy } from '@angular/core';
import { Activation } from './interface';
import { Observable, Subscription } from 'rxjs';
// import { timer } from 'rxjs/observable/timer';
import { timer } from 'rxjs';
import { User } from './user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'watch-command';
  FIVE_MINUTES = 5 * 60 * 1000;
  // FIVE_HOURS = 5 * 60 * 60 * 1000;
  FIVE_HOURS = 5 * 60 * 60 * 1000;
  public tour = '';
  public duty = '';
  public activate = '';
  private timer;
  private sub: Subscription;
  public user: User;
  // message: Activation;

  // constructor(private data: ServiceService) {}
  ngOnInit() {
    // this.data.currentMessage.subscribe(message => this.message = message);
    timer(this.FIVE_HOURS).subscribe(() => {
      window.location.reload();
    });
  }

  ngOnDestroy() {
    timer;
  }
}
