import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CarTable, Vehicle } from './interface';
import { Subscription, Observable, timer } from 'rxjs';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css'],
})
export class VehicleComponent implements OnInit, OnDestroy {
  private serviceUrl = environment.serverUrl + '/api-vehicle';
  FIVE_MINUTES = 5 * 60 * 1000;
  vehicles: Vehicle;
  // cars: CarTable;
  displayedColumns: string[] = [
    'type',
    'name',
    'location',
    'available',
  ];
  private timer;
  private sub: Subscription;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.timer = timer(1000, this.FIVE_MINUTES);
    this.sub = this.timer.subscribe(() => {
      this.http.get<any>(this.serviceUrl).subscribe((response) => {
        this.vehicles = response.vehicle.vehicles;
        // console.log('this.vehicles :', response);
      });
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
