import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { DataService } from '../data.service';
import { Activation } from './interface';
import { analyzeAndValidateNgModules } from '@angular/compiler';

// export interface Activate {
//   activate: boolean;
// }

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css'],
})
export class ActivationComponent implements OnInit, OnDestroy {
  // act = { activation: true };
  // act2 = { activation: true };

  // @Output() public activate = new EventEmitter();
  private ACT_URL = environment.serverUrl + '/api-activation';
  FIVE_MINUTES = 20 * 1000;
  act: Activation;
  response: any;

  myTest = 'Testing activation';
  private timer;
  private sub: Subscription;
  constructor(
    private http: HttpClient,
    private activateService: DataService,
  ) {}
  ngOnInit(): void {
    this.timer = timer(10, this.FIVE_MINUTES);

    this.sub = this.timer.subscribe(() => {
      // this.response = this.http.get<any>(this.ACT_URL);
      // this.response.subscribe((results) => {
      // 	this.act = results.activation.activation;
      // 	console.log('act from api ===> ', this.act);
      // 	// this.activate.emit(this.act);
      // 	// this.activateService.getActivationData(this.act);
      // 	// console.log('this.act :', this.act);
      // });
      this.http.get<any>(this.ACT_URL).subscribe((response) => {
        this.act = response.activation.activation[0];
        this.activateService.getActivationData(this.act);
        // this.activateService.getActivationData(this.activation);

        // console.log('this.act :', this.act.activation);
      });
    });

    // this.activateService.activateData.subscribe(a => (this.act2 = a));
    // console.log('this.act2 :', this.act2);
  }

  // testActivationFalse: any ;
  activationFalse = {
    meta: { saveDate: new Date() },
    activation: {
      activation: false,
      title: '',
      content: '',
      beginDate: new Date(),
      upDate: new Date(),
    },
  };
  activation = {
    activation: false,
    title: '',
    content: '',
    beginDate: new Date(),
    upDate: new Date(),
  };
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
