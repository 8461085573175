import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { OAuthConfig } from 'src/config/config';
import { ReplaySubject } from 'rxjs';
import { User } from './user';
import { Logger, CryptoUtils } from 'msal';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // Account observable for components to subscribe to get authenticated user
  public account: ReplaySubject<object>;
  private user: User;

  constructor(private msalService: MsalService) {
    this.account = new ReplaySubject(1);

    // Add event listener to monitor user's token 
    // So sign in/out can affect all open tabs 
    window.addEventListener("storage", this.clearSessionStorage.bind(this), false);

    // Check if user is already signed-in and update variables
    this.getAccount();
  }

  /**
   * Construct user object and update account subscribers 
   */
  private getAccount() {
    let azrAccount = this.msalService.getAccount();

    // Monitor storage to logout from all tabs
    if (azrAccount) {
      this.user = {
        displayName: azrAccount.name,
        email: azrAccount.userName
      };
    } else {
      this.user = null;
    }

    // update subscribers
    this.account.next(this.user);
  }

  /** 
   * Prompt the user to sign in and
   * grant consent to the requested permission scopes
   */
  public signIn(): void {
    this.msalService.loginPopup(OAuthConfig)
      .then(result => {
        console.log('Login Success: ' + JSON.stringify(result, null, 2));
        this.getAccount();
      })
      .catch((reason) => {
        console.log('Login failed: ' + JSON.stringify(reason, null, 2));
      });
  }

  /** Sign out */
  public signOut(): void {
    this.msalService.logout();
    this.account.next(null);
  }

  /**
   * Clear session storage when user logs out
   * and refresh the page
   */
  private clearSessionStorage(event: StorageEvent) {
    sessionStorage.clear();
    window.location.reload();
  }


}
