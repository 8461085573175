
<div class="table-vehicle">
  <table mat-table [dataSource]="vehicles" matSort class="table mat-elevation-z1 table-font-size mat-display-10">
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef class="table-font-size"> ASSET </th>
      <td mat-cell *matCellDef="let element" class="table-font-size"> {{element.type}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="table-font-size"> NAME </th>
      <td mat-cell *matCellDef="let element" class="table-font-size"> {{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef class="table-font-size"> LOCATION </th>
      <td mat-cell *matCellDef="let element" class="table-font-size"> {{element.location}} </td>
    </ng-container>
    <ng-container matColumnDef="available">
      <th mat-header-cell *matHeaderCellDef class="table-font-size"> AVAILABLE </th>
      <td mat-cell *matCellDef="let element" class="table-font-size"> {{element.available}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="mat-header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>