import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { timer, Observable, Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { DutyExecutive } from './interface';
// import { DutyExecutive } from '../response/interface';

@Component({
  selector: 'app-duty',
  templateUrl: './duty.component.html',
  styleUrls: ['./duty.component.css'],
})
export class DutyComponent implements OnInit, OnDestroy {
  DUTY_URL = environment.serverUrl + '/api-duty';
  // DUTY_URL = 'http://cpv-stg-iis02.oem.nycnet:3000/api-duty';

  FIVE_MINUTES = 5 * 60 * 1000;
  duty: DutyExecutive;
  private j = 0;
  private timer;
  private sub: Subscription;
  constructor(
    private http: HttpClient,
    private dutyService: DataService,
  ) {
    // this.http.get<DutyExecutive>(this.DUTY_URL).subscribe(response => {
    //   this.duty = response;
    // });
  }

  ngOnInit() {
    this.timer = timer(0, this.FIVE_MINUTES);

    this.sub = this.timer.subscribe(() => {
      this.http.get<any>(this.DUTY_URL).subscribe((response) => {
        this.duty = response.duty.duty;
        this.dutyService.getDutyExecutive(this.duty);
        // console.log('response.duty :>> ', this.duty);
        // console.log(`duty: this.duty :${this.j++}`, this.DUTY_URL);
        // console.log(`duty: this.duty :${this.j++}`, this.duty);
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
