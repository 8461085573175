import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Weather, Hazard, ForecastCard } from './interface';
import { Observable, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-weather-card',
  templateUrl: './weather-card.component.html',
  styleUrls: ['./weather-card.component.css'],
})
export class WeatherCardComponent implements OnInit, OnDestroy {
  private weatherUrl = environment.serverUrl + '/api-weather';
  private FIVE_MINUTES = 60 * 1000;
  private timer;
  private sub: Subscription;
  forecastCards: any;
  response: any;

  hazard: Hazard;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.timer = timer(0, this.FIVE_MINUTES);
    this.sub = this.timer.subscribe(() => {
      this.response = this.http.get<Weather>(this.weatherUrl);
      this.response.subscribe((results) => {
        this.forecastCards = results.weather.forecastCard;
        this.hazard = results.weather.hazard[0];

        // this.forecastCards.forEach((element) => {
        // 	element.push(element.temperature1.split(':', 1));
        // 	console.log(element.temperature1.split(':', 1));
        // });
        // console.log('forecast :', this.forecastCards);
      });
    });
  }

  ngOnDestroy() {
    this.response.unsubscribe.next();
    this.response.unsubscribe.complete();
    this.sub.unsubscribe();
  }
}
