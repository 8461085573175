
<div class="table-rollcall">

  <table mat-table [dataSource]="rollcalls" matSort class="mat-elevation-z1 table-font-size mat-display-10">
    <!-- <ng-container matColumnDef="CallID">
      <th mat-header-cell *matHeaderCellDef   class="table-font-size"> id </th>
      <td mat-cell *matCellDef="let element"  class="table-font-size"> {{element.CallID}} </td>
    </ng-container> -->
    <ng-container matColumnDef="RcName">
      <th mat-header-cell *matHeaderCellDef class="table-font-size"> ROLL CALL </th>
      <td mat-cell *matCellDef="let element" class="table-font-size"> {{element.RcName}} </td>
    </ng-container>
    <ng-container matColumnDef="NextExe">
      <th mat-header-cell *matHeaderCellDef class="table-font-size"> DATE </th>
      <td mat-cell *matCellDef="let element" class="table-font-size"> {{element.NextExe | date:'MM/dd - HH:mm ':'UTC'}}
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <!-- <hr> -->
</div>