<div class="hazard z-depth-1">
  <div *ngIf="hazard">
    <a href="{{ hazard.Hazardhref }}" target="_blank" class="hazard">{{
      hazard.HazardDetails
    }}</a>
    <p></p>
  </div>
</div>

<div id="seven-day-forecast-container">
  <ul id="seven-day-forecast-list" class="list-card">
    <div *ngFor="let w of forecastCards" class="forecast">
      <p class="period-name">{{ w.periodName }}</p>
      <p class="weather-image tooltip">
        <img
          src="{{ w.img }}"
          alt="{{ w.longDescription }}"
          title="{{ w.longDescription }}"
        />
      </p>
      <div class="short-desc">{{ w.shortDescription }}</div>
      <p
        [ngClass]="w.temperature1.startsWith('High') ? 'temp-high' : 'temp-low'"
      >
        {{ w.temperature1 }}
      </p>
    </div>
  </ul>
</div>
