import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { environment } from '../../environments/environment';
import { Tour } from './interface';
import { HttpClient } from '@angular/common/http';
// import { timer } from 'rxjs/observable/timer';
import { Observable, Subscription, timer } from 'rxjs';
// import { concatMap, switchMap } from 'rxjs/operators';
// import { interval } from 'rxjs';
import { DataService } from '../data.service';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css'],
})
export class TourComponent implements OnInit, OnDestroy {
  @Output() public tour = new EventEmitter();
  private url = environment.serverUrl + '/api-tour';
  tourData: Tour;
  response: any;
  FIVE_MINUTES = 5 * 60 * 1000;

  displayedColumns: string[] = [
    'leadFirstName',
    'leadLastName',
    'tourStart',
    'tourEnd',
    'tourColor',
  ];
  private timer;
  private sub: Subscription;
  constructor(
    private http: HttpClient,
    private tourService: DataService,
  ) {}

  ngOnInit() {
    this.timer = timer(1100, this.FIVE_MINUTES);

    this.sub = this.timer.subscribe(() => {
      this.response = this.http.get<Tour>(this.url);
      this.response.subscribe((results) => {
        this.tourData = results.tour.tour;
        // console.log('tourData 1 ===> ', this.tourData.tourStart);
        // console.log('tourData 1 ===> ', this.tourData);
        // this.tour.emit(this.tourData);
        this.tourService.getTourTeamData(this.tourData);
      });
    });
  }

  ngOnDestroy() {
    this.response.unsubscribe.next();
    this.response.unsubscribe.complete();

    this.tour.unsubscribe();
    this.sub.unsubscribe();
  }
}
