import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import { timer } from 'rxjs/observable/timer';
import { timer } from 'rxjs';

import { Activation } from './activation/interface';
import { DutyExecutive } from './duty/interface';
import { HttpClient } from '@angular/common/http';
import { Tour } from './tour/interface';

@Injectable({
  providedIn: 'root',
})
export class DataService implements OnInit, OnDestroy {
  // private ActivationSubj = new BehaviorSubject<Activation>({
  //   activation: true
  // });
  URL = 'https://vtran-dev.oem.nycnet:3000/api-activation';
  FIVE_MINUTES = 60 * 5 * 1000;
  ActData: Activation;
  private ActivationSubj = new BehaviorSubject<Activation>(
    this.ActData,
  );
  public activateData = this.ActivationSubj.asObservable();

  dutyData: DutyExecutive;
  // dutyData2: DutyExecutive;
  private dutySubj = new BehaviorSubject<DutyExecutive>(
    this.dutyData,
  );
  public dutyExecutiveData = this.dutySubj.asObservable();

  tourData: Tour;
  private tourSubj = new BehaviorSubject<Tour>(this.tourData);
  public tourTeamData = this.tourSubj.asObservable();

  constructor(private http: HttpClient) {}

  ngOnInit() {
    // console.log('SERVICE: HELLO WORLD');
    // timer(0, this.FIVE_MINUTES).subscribe(() => {
    //   this.http.get<DutyExecutive>(this.DUTY_URL).subscribe(response => {
    //     this.dutyData = response;
    //     this.getDutyExecutive(this.dutyData2);
    //     console.log('Service: this.dutyData :', this.dutyData2);
    //   });
    // });
    timer(0, 10 * 1000).subscribe(() => {
      this.http.get<Activation>(this.URL).subscribe((response) => {
        this.ActData = response;
        this.getActivationData(this.ActData);
        // console.log('Service: this.ActData :', this.ActData);
      });
    });
  }
  getActivationData(nextData: Activation) {
    this.ActivationSubj.next(nextData);
    // this.activateData.next(data);
    // console.log('nextData :', nextData);
  }

  getDutyExecutive(nextData: DutyExecutive) {
    this.dutySubj.next(nextData);
    // console.log('nextData :>> ', nextData);
  }

  getTourTeamData(nextData: Tour) {
    this.tourSubj.next(nextData);
  }

  ngOnDestroy() {
    this.ActivationSubj.unsubscribe();
    this.dutySubj.unsubscribe();
    this.tourSubj.unsubscribe();
  }
}
