<div class="table-staff">
  <table
    mat-table
    [dataSource]="staffs"
    matSort
    class="mat-elevation-z1 table-font-size mat-display-10"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="table-font-size">NAME</th>
      <td mat-cell *matCellDef="let element" class="table-font-size">
        {{ element.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="start">
      <th mat-header-cell *matHeaderCellDef class="table-font-size">START</th>
      <td mat-cell *matCellDef="let element" class="table-font-size">
        {{ element.start }}
      </td>
    </ng-container>
    <ng-container matColumnDef="end">
      <th mat-header-cell *matHeaderCellDef class="table-font-size">END</th>
      <td mat-cell *matCellDef="let element" class="table-font-size">
        {{ element.end }}
      </td>
    </ng-container>
    <ng-container matColumnDef="radio">
      <th mat-header-cell *matHeaderCellDef class="table-font-size">RADIO</th>
      <td mat-cell *matCellDef="let element" class="table-font-size">
        {{ element.radio }}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="table-font-size">STATUS</th>
      <td mat-cell *matCellDef="let element" class="table-font-size">
        {{ element.status }}
      </td>
    </ng-container>
    <ng-container matColumnDef="assignment">
      <th mat-header-cell *matHeaderCellDef class="table-font-size">DATE</th>
      <td mat-cell *matCellDef="let element" class="table-font-size">
        {{ element.assignment }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!-- <hr> [ 'name', 'start', 'end', 'radio', 'status', 'assignment' ] -->
</div>
