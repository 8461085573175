import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { DataService } from '../data.service';
import { Activation } from '../activation/interface';
// import { Subject } from 'rxjs';
import { timer } from 'rxjs';
import { DutyExecutive } from '../duty/interface';
import { Tour } from '../tour/interface';
import { AuthService } from '../auth.service';
import { User } from '../user';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  // private unsubscribe = new Subject<void>();
  // tour = '';
  FIVE_MINUTES = 60 * 5 * 1000;
  act: any;
  duty: any;
  tour: Tour;
  showAct: boolean;
  // act = { activation: true };
  constructor(private dataService: DataService, private authService: AuthService) {}

  public now: Date = new Date();

  // Event emitter to send user object to the app module
	@Output() private accountEvent = new EventEmitter();
	public user: User;
    private accountObserver = {
        error: (err: string) => {
            console.warn('Account observer ERROR : ' + err)
            this.user = null;
            this.accountEvent.emit(this.user);
        },
        next: (value: User) => {
            this.user = value;
            this.accountEvent.emit(this.user );
        }
  }
  
  ngOnInit() {
    setInterval(() => {
      this.now = new Date();
    }, 10);

    this.dataService.activateData.subscribe((res) => {
      this.act = res;
      // this.showAct = this.act.activation.activation;
      if (this.act != undefined) {
        // console.log('res :', this.showAct);
        this.showAct = this.act.activation;
      }
    });
    // this.dataService.getActivationData(this.act);
    // console.log('menu: act ---> ', this.act);
    this.dataService.dutyExecutiveData.subscribe((res) => {
      this.duty = res;
      // console.log('dutyExecutive res :>> ', res);
      // console.log('menu: this.duty :', this.duty);
    });
    this.dataService.tourTeamData.subscribe(
      (res) => (this.tour = res),
    );
    // console.log('menu: this.tour :', this.tour);
    // timer(1000, this.FIVE_MINUTES).subscribe(() => {});

    // Subscrib to authentication service account observable
    this.authService.account.subscribe(this.accountObserver);
  }

  signIn() {
    this.authService.signIn();
}

signOut() {
    this.authService.signOut();
}

  // ngOnDestroy(): void {
  //   //Called once, before the instance is destroyed.
  //   //Add 'implements OnDestroy' to the class.
  //   this.dataService.activateData.unsubscribe.next();
  //   this.dataService.activateData.unsubscribe.complete();
  // }
  // ngOnDestroy() {
  // 	this.dataService.activateData.unsubscribe();
  // }
}
