export const OAuthConfig = {
    //Enter the Cloud Instance Id here (Tenant Id)
    authority: 'https://login.microsoftonline.com/b8fb6613-a061-47c7-8b96-0f31f251d8a6',
    //Enter the Application Id here
    clientId: '62c6f7f9-cba1-4b4c-9896-e573cd3d9344',     
    redirectUri: 'https://wc.nycem.net',    
    scopes: ['openid', 'user.read']
};

export const protectedResourceMap: [string, string[]][] = [
    ['https://cpv-stg-iis02.oem.nycnet:3000/', [OAuthConfig.clientId + "/.default"]]
];

