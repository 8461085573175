<table mat-table
  [dataSource]="dataSource" multiTemplateDataRows matSort matSortDisableClear class="mat-elevation-z8">
  <!-- <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
    <th mat-header-cell *matHeaderCellDef> {{column}} </th>
    <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
  </ng-container> -->
  <ng-container matColumnDef="statusIcon">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-font-size">STATUS</th>
    <td mat-cell *matCellDef="let element" class="table-font-size">
      <span> <span>
        </span></span>
      <i class='material-icons {{element.statusColorText}} '>
        {{element.statusIcon}}</i>
    </td>
  </ng-container>

  <ng-container matColumnDef="incident">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-font-size" [ngClass]="'customWidthClass'">
      INCIDENT </th>
    <td mat-cell *matCellDef="let element" class="table-font-size mat-cell" [ngClass]="'customWidthClass'">
      {{element.incident}} </td>
  </ng-container>

  <ng-container matColumnDef="STREET_ADDRESS">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-font-size"> ADDRESS </th>
    <td mat-cell *matCellDef="let element" class="table-font-size"> {{element.STREET_ADDRESS}} </td>
  </ng-container>

  <ng-container matColumnDef="MODIFICATION_DATE">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-font-size"> DATE </th>
    <td mat-cell *matCellDef="let element" class="table-font-size"> {{element.MODIFICATION_DATE | date:'MM/dd -
      HH:mm':'UTC'}}
    </td>
  </ng-container>
  <!-- | date: 'MM/dd HH:mm:ss' -->

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
      <div class="example-element-detail"
        (click)="expandedElement= expandedElement === element ? null : element"
        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="example-element-diagram">
          <div class="logo">
            <img src="./assets/logo.png" alt="">

          </div>
          <!-- <div class="example-element-position"> {{element.position}} </div>
          <div class="example-element-symbol"> {{element.symbol}} </div>
          <div class="example-element-name"> {{element.name}} </div>
          <div class="example-element-weight"> {{element.weight}} </div> -->
        </div>
        <div class="example-element-description example-element-description-nl">
          {{element.summaryText}}
          <!-- <span class="example-element-description-attribution"> -- by NYCEM </span> -->
        </div>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
    class="example-element-row"
    [class.example-expanded-row]="expandedElement === element"
    (click)="expandedElement= expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>

<router-outlet></router-outlet>
