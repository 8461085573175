import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
// import { timer } from 'rxjs/observable/timer';
import { timer } from 'rxjs';

import { DataSource } from '@angular/cdk/collections';
import { Incident } from './interface';
import { ICON_REGISTRY_PROVIDER } from '@angular/material/icon';
// import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { switchMap, concatMap } from 'rxjs/operators';
// import { Observable, Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { analyzeAndValidateNgModules } from '@angular/compiler';
// import { Observable } from 'rxjs';
// import { element } from '@angular/core/src/render3';

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed, void',
        style({ height: '0px', minHeight: '0', display: 'none' }),
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0, 0.0, 1, 1)'),
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0, 0.0, 1, 1)'),
      ),
    ]),
  ],
})
export class IncidentComponent implements OnInit {
  constructor(private http: HttpClient) {}
  // @ViewChild(MatSort) sort: MatSort;
  // private serviceUrl = 'http://localhost:3000/api-incidents/55';
  private incidentURL =
    environment.serverUrl + '/api-incidents-sum-all';

  FIVE_MINUTES = 5 * 60 * 1000;
  panelOpenState = false;
  incidents: any;
  incidentParseArray: any;
  incidents_sortable: any;
  columnsToDisplay: string[] = [
    'statusIcon',
    'incident',
    'STREET_ADDRESS',
    'MODIFICATION_DATE',
    // 'prognosisIcon'
  ];
  rows: any;
  dataSource: MatTableDataSource<Incident>;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  // dataSource: Incident;

  expandedElement: Incident | null;

  isExpansionDetailRow = (index, row) =>
    row.hasOwnProperty('detailRow');

  ngOnInit() {
    timer(1500, this.FIVE_MINUTES).subscribe(() => {
      this.http
        .get<Incident>(this.incidentURL)
        .subscribe((response) => {
          this.incidents = response;
          // console.log('IncidentComponent -> ngOnInit -> response', this.incidents);

          const incidentArray = Object.values(
            this.incidents.incident.incident,
          );
          // console.log('array ===> ', incidentArray);
          this.incidentParseArray = incidentArray.map(
            ({
              INCIDENT_NAME,
              STREET_ADDRESS,
              eStatus,
              eSTATUS_COLOR,
              ePROGNOSIS,
              ePROGNOSIS_COLOR,
              MODIFICATION_DATE,
              summaryText,
            }) => {
              const [incident, aux] = INCIDENT_NAME.split(/-\s+/, 2);
              const [status, active] = eStatus.split(/-\s+/, 2);
              const [junk0, statusColor, junk1] = eSTATUS_COLOR.split(
                /\./,
                3,
              );
              const [
                junk2,
                prognosisColor,
                junk3,
              ] = ePROGNOSIS_COLOR.split(/\./, 3);
              const statusIcon = assignStatusIcon(statusColor);
              const prognosisIcon = assignPrognosisIcon(ePROGNOSIS);
              const prognosisColorText = assignPrognosisColor(
                prognosisColor,
              );
              const statusColorText = assignStatusColor(statusColor);
              // console.log('colorStatus :', prognosisColor);
              // const [zoneName, zoneCodeTemp] = zone.split('(', 2);
              // const zoneCode = zoneCodeTemp;
              // console.log('incident name => ', INCIDENT_NAME);
              // console.log('incident: ', incident);
              return {
                incident,
                status,
                statusColor,
                statusIcon,
                statusColorText,
                prognosisColor,
                prognosisColorText,
                STREET_ADDRESS,
                ePROGNOSIS,
                prognosisIcon,
                MODIFICATION_DATE,
                summaryText,
              };
            },
          );

          let incidentsFiveDays: any;
          incidentsFiveDays = this.incidentParseArray.filter(
            function (element) {
              var x = element.MODIFICATION_DATE;
              var y = new Date(element.MODIFICATION_DATE);
              // console.log('*****MODIFICATION_DATE: ', y, typeof y);
              var dt = new Date();
              // dt is the date 5 days ago
              dt.setDate(dt.getDate() - 5);
              // console.log('*****FIVE DAYS AGO: ', dt, typeof dt);

              if (y > dt) {
                // console.log('x: ', x, ' y: ', y, ' dt: ', dt);
                // return this.expandedElement.push(element);
                return element;
              }
            },
          );

          this.expandedElement = incidentsFiveDays;
          this.dataSource = new MatTableDataSource<Incident>(
            incidentsFiveDays,
          );
          this.dataSource.sort = this.sort;
          // todo: create new array and push detailRow: true
          // this.expandedElement = this.incidentParseArray;
          // this.dataSource = new MatTableDataSource<Incident>(this.incidentParseArray);
          // this.dataSource.sort = this.sort;
          // console.log(this.expandedElement);
          // console.log(this.dataSource);
          // this.incidentParseArray.forEach(element =>
          //   this.rows.push(element, { detailRow: true, element })
          // );
        });
    });

    // interval(60 * 1000).pipe(
    //   concatMap( () => this.http.get<Incident>(this.serviceUrl) ),
    //   ).subscribe(response => this.incidents = response);

    // this.incidents_sortable = new MatTableDataSource(this.incidents);
    // this.incidents_sortable.sort = this.sort;
    // console.log(this.incidents_sortable);
  }
  // ngOnDestroy() {
  //   this.sub.unsubscribe();
  // }
  // ngOnDestroy() {
  //   // Emit something to stop all Observables
  //   // this.incidents.next();
  //   // Complete the notifying Observable to remove it
  //   // timer.unsubscribe();
  //   this.incidents.unsubscribe();
  //   // this.dataSource.unsubscribe();
  // }
}

// export class ExampleDataSource extends DataSource<any> {
//   /** Connect function called by the table to retrieve one stream containing the data to render. */
//   connect(): Observable<Element[]> {
//     const rows = [];
//     data.forEach(element => rows.push(element, { detailRow: true, element }));
//     console.log(of(rows));
//     return of(rows);
//   }

//   disconnect() {}
// }

function assignStatusIcon(color) {
  let icon = '';
  if (color === 'gray') {
    icon = 'brightness_1';
  } else if (color === 'green') {
    icon = 'lens';
  } else if (color === 'red') {
    icon = 'remove_circle';
  } else {
    icon = 'broken_image';
  }
  return icon;
}

function assignStatusColor(color) {
  let pcolor = '';
  if (color === 'gray') {
    pcolor = 'grey-text';
  } else if (color === 'green') {
    pcolor = '#00c853 green-text accent-4';
  } else if (color === 'red') {
    pcolor = '#00c853 red-text accent-4';
  } else {
    pcolor = 'black-text';
  }
  return pcolor;
}

function assignPrognosisIcon(str) {
  let icon = '';
  if (str === 'Monitoring') {
    icon = 'remove_red_eye';
  } else if (str === 'Response') {
    icon = 'directions_walk';
  } else {
    icon = 'broken_image';
  }
  return icon;
}

function assignPrognosisColor(color) {
  let pcolor = '';
  if (color === 'grey') {
    pcolor = 'grey-text';
  } else if (color === 'red') {
    pcolor = 'red-text';
  } else {
    pcolor = 'black-text';
  }
  return pcolor;
}

// export class ExampleDataSource extends DataSource<any> {
//   /** Connect function called by the table to retrieve one stream containing the data to render. */
//   connect(): Observable<Incident[]> {
//     const rows = [];
//     IncidentComponent.this.incidentParseArray.forEach(element =>
//       rows.push(element, { detailRow: true, element })
//     );
//     console.log(rows);
//     return of(rows);
//   }

//   disconnect() {}
// }
